require('./bootstrap');
window.bootbox = require('bootbox'); // Add the bootbox js (from node_modules) here to be included in webmix

class Page {
    constructor() {
        this._hasPageBeenEdited = false;
    }

    _bindChangeListenerToInputs() {
        $('input, textarea, select').on('change', () => {
            this._hasPageBeenEdited = true;
        });
    }

    enableUnsavedChangesPrompt() {
            this._bindChangeListenerToInputs();

            this.checkForUnsavedChanges = (e) => {
                if (this._hasPageBeenEdited) {
                    e.returnValue = 'Changes that you made may not be saved. Are you sure you want to leave this page?';
                }
            };

            window.addEventListener('beforeunload', this.checkForUnsavedChanges);
    }

    flagPageAsChanged() {
        this._hasPageBeenEdited = true;
    }

    disabledUnsavedChangesPrompt() {
        window.removeEventListener('beforeunload', this.checkForUnsavedChanges);
    }
}

window.page = new Page();
